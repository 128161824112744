import React from "react";
import Layout from "../components/Layout";

const UnderConstructionPage = () => (
  <Layout>
    <div className="construction">
      <h1>Under Construction</h1>
      <p>
        This page is currently being built by our Oompaloompas. Check again
        shortly as they'll be done soon.
      </p>
      <p>We don't give them breaks after all...</p>
    </div>
  </Layout>
);

export default UnderConstructionPage;
